import React from "react"
import { Link } from "gatsby"

import './helpful_resources.css'

/*images*/
import icon_01 from '../../images/4.0/landingpage_icons_v2-11.png'
import icon_02 from '../../images/4.0/landingpage_icons_v2-12.png'
import icon_03 from '../../images/4.0/landingpage_icons_v2-13.png'

import bck from "../../images/landingpage_images/3.0_landingpage_image_v55.jpg";



export default (props) => {

  return (
    <section className="landing-wrapper grey-bg">
      <h1 style={{display: "none" }}>Dosing: Information about dosing, including direction for patients</h1>

        <div className="landing-grid-container three-column landing-grid-container-top-margin">
            {/* <h2 className="landing-headline">Getting Patients Started</h2> */}

            <article className="landing-content">
              <img className="landing-icon" src={icon_01} alt="Stethoscope icon"/>
              <h2 className="landing-subhead">HCP resources</h2>
              <p className="landing-copy">Downloadable resources<br/> to support your practice</p>
              <Link className="homeBtn" to="/resources/hcps/">Get them now</Link>
            </article>
            {props.isMobile ? <hr/> : null}
            <article className="landing-content">
              <img className="landing-icon" src={icon_02} alt="Medical cross icon"/>
              <h2 className="landing-subhead">Pharmacist resources</h2>
              <p className="landing-copy">Downloadable resources for pharmacists dispensing VASCEPA</p>
              <Link className="homeBtn" to="/resources/pharmacists/">Get them now</Link>
            </article>
            {props.isMobile ? <hr/> : null}
            <article className="landing-content">
            <img className="landing-icon" src={icon_03} alt="Apple icon"/>
              <h2 className="landing-subhead">Patient resources</h2>
              <p className="landing-copy">Downloadable resources for your<br/> patients</p>
              <Link className="homeBtn" to="/patient-resources/">Get them now</Link>
            </article>


        </div>
    </section>
  )
}
